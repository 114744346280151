<template>
  <div class="basic-data-add">
    <header-path :paths="headerPaths" />
    <el-card class="add-card">
      <m-form
        ref="myForm"
        :form-data="baseFormData"
        label-position="right"
        :label-width="120"
        :button="button"
        class="basic-data-add-form"
        @submit="onSubmit"
        @reset="onCancel"
      >
        <template #inputCodeTipIcon="{ item }">
          <el-tooltip
            popper-class="workshop_code_tip-icon"
            effect="dark"
            :content="getToolTipContent(item)"
            placement="right"
          >
            <i class="el-icon-tip el-icon-question" />
          </el-tooltip>
        </template>
      </m-form>
    </el-card>
  </div>
</template>
<script>
import HeaderPath from '@/components/headerPath/index.vue'
import MForm from '@/libs_sz/components/MForm/MForm'
import { getEditBaseFormData } from '../data'
import addMixins from '@/mixins/addMixins'
import commonMixins from '@/mixins/commonMixins'
import { mapState, mapActions } from 'vuex'

export default {
  name: 'MaterialConfigAdd',
  components: {
    MForm,
    HeaderPath
  },
  mixins: [addMixins, commonMixins],
  props: {
    // 新增/修改模式
    mode: {
      type: String,
      default: 'add'
    },
    // 编辑或者查看详情 行信息
    rowDetail: {
      type: Object,
      default: () => ({})
    },
    options: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      workPositionList: [],
      formModel: {},
      conutKey: 1
    }
  },
  computed: {
    ...mapState('materialManage', ['materialPlaceList', 'materialTypsList']),
    // 弹框 title
    headerPaths() {
      return this.mode === 'add'
        ? [this.$t('lang.gles.base.materialConfig.workPositionAdd')]
        : [this.$t('lang.gles.base.materialConfig.workPositionEdit')]
    },
    baseFormData() {
      const options = {
        ...this.options
      }
      return getEditBaseFormData(this, options, { ...this.formModel })
    }
  },
  created() {
    this.formModel = { ...this.rowDetail }
    if (this.mode === 'edit') {
      this.getWorkPositionByType({ type: this.formModel.workPositionType })
      if (this.workPositionList) {
      }
    }
    this.initSelectList()
  },
  methods: {
    ...mapActions('materialManage', ['getMaterialmaterialCodeList']),
    /**
     * 初始化下拉选项
     */
    initSelectList() {
      this.getMaterialmaterialCodeList()
    },
    getToolTipContent(item) {
      if (item.name === 'warehouseAreaId') {
        return '自动生成2位字母+6位数字的唯一编码；可输入50个字符以内的字母、数字、-或上述组合'
      }
      return '【所属车间】和【所属仓库】二选其一；与生产车间相关时选择车间，与仓储相关时选择仓库'
    },
    // 工位类型
    handleWorkPositionType(val, formItem) {
      // 防止触法 级联组件options 数据 报错
      this.$set(this.formModel, 'workPositionKey', this.conutKey++)

      this.$set(this.formModel, 'workPositionId', '')
      this.$set(this.formModel, formItem.name, val)
      this.workPositionList = []
      if (!val) {
        return
      }
      console.log(this.formModel)
      this.getWorkPositionByType({ type: val })
    },
    // 根据工位类型 获取 工位位置
    async getWorkPositionByType(params) {
      const { data, code } = await this.$httpService(
        this.$apiStore.materialManage('getMaterialConfigMoreList'), // 工位位置 list
        params
      )

      if (code !== 0) {
        return
      }
      const list = data ?? []
      const workPositionListd = list.map((item) => {
        // 工位位置
        let value = ''
        let label = ''
        let children = []
        if (params.type === 1) {
          value = `${item.areaId},${item.productionLineId},${item.deviceId}`
          label = item.deviceName
        } else if (params.type === 2) {
          value = `${item.areaId}`
          label = item.areaName
        }
        children = (item?.workPositions || []).map((t) => {
          return {
            // value: `${t.workPositionId},${t.workPositionCode}`,
            value: `${t.workPositionId}`,
            label: t.workPositionCode
            // disabled: !!t.exist,
          }
        })
        return {
          label,
          value,
          children
        }
      })
      this.workPositionList = workPositionListd
      this.$set(
        this.formModel,
        'workPositionId',
        this.deepWorkPositionListfind(this.formModel)
      )
    },
    deepWorkPositionListfind(data) {
      const that = this
      let resultData = {}
      if (this.workPositionList.length) {
        this.workPositionList.forEach((item, index) => {
          Object.keys(item).forEach((key) => {
            if (key === 'children') {
              item[key].forEach((curent) => {
                if (+curent.value == +data.workPositionId) {
                  resultData = {
                    firstVal: that.workPositionList[index].value,
                    workPositionId: curent.value,
                    workPositionCode: curent.label
                  }
                }
              })
            }
          })
        })
        if (resultData.firstVal) {
          if (data.workPositionType === 1) {
            return [`${resultData.firstVal}`, `${data.workPositionId}`]
          } else if (data.workPositionType === 2) {
            return [`${resultData.firstVal}`, `${data.workPositionId}`]
          } else {
            return []
          }
        } else {
          return []
        }
      }
    },
    handleWorkPosition(val, formItem, changeFormValue, formModel) {
      this.formModel.workPositionId = val
    },
    /**
     * 所属工厂 change
     */
    handleFactoryChange(val, formItem, changeFormValue, formModel) {
      this.$set(this.formModel, formItem.name, val)
      const type = val ? 'cascade' : null
      this.resetWorkshop(this.formModel, type)
      this.resetWarehouse(this.formModel, type)
    },
    onSubmit() {
      // 先校验
      this.$refs.myForm.getValidateFormModel().then(async(model) => {
        this.formModel.workPositionNextlabel = this.formModel.workPositionId
        const workId = +this.formModel.workPositionNextlabel[1].split(',')[0]
        this.formModel.workPositionId = workId
        const params = { ...this.formModel }
        this.mode === 'add'
          ? await this.insert(params)
          : await this.update(params)
      })
    },
    /**
     * 新增
     */
    async insert(params) {
      const { code, msg } = await this.$httpService(
        this.$apiStore.materialManage('addMaterialConfig'),
        params
      )
      if (code !== 0) {
        return
      }
      this.onCancel()
      this.$message.success(this.$t(msg))
    },
    /**
     * 编辑
     */
    async update(params) {
      const { code } = await this.$httpService(
        this.$apiStore.materialManage('UpdateMaterialConfig'),
        params
      )
      if (code !== 0) {
        return
      }
      this.onCancel()
      this.$message.success(this.$t('lang.gles.common.updateSuccessfully'))
    },
    onCancel() {
      this.$emit('updateCom', {
        currentCom: 'MaterialConfigList'
      })
    }
  }
}
</script>
<style scoped lang="scss">
body {
  height: 100%;
  .basic-data-add /deep/ .el-card {
    overflow: inherit;
  }
}
/deep/ .materCode {
  .el-select-dropdown__wrap {
    max-width: 100% !important;
  }
}
</style>
