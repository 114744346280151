import mapVerify from '@/utils/formValidate'

// 获取{{ $t('lang.gles.common.moreQuery') }}字段列表
export const getMoreQueryFormData = (that, row) => {
  const moreQueryData = [
    // 工位类型
    {
      name: 'workPositionType',
      value: row.workPositionType || [],
      span: 12,
      component: 'elSelect',
      adaptionw: true,
      showWordLimit: false,
      data: that.materialTypsList || [],
      label: that.$t('lang.gles.workflow.workPositionType'),
      clearable: true,
      mchange: that.handleWorkPositionType
    },
    // 工位位置
    {
      name: 'workPositionId',
      value: row.workPositionId || '',
      value1: row.workPositionNextlabel || '',
      span: 12,
      component: 'elCascader',
      adaptionw: true,
      showWordLimit: false,
      options: that.workPositionList || [],
      label: that.$t('lang.gles.workflow.workPosition'),
      clearable: true,
      key: row.workPositionKey || 0
    },
    // 工位名称
    {
      name: 'workPositionName',
      value: row.workPositionName || '',
      span: 12,
      component: 'elInput',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.workflow.workPositionName')
    },
    // 物料编码
    {
      name: 'materialId',
      value: row.materialId || '',
      span: 12,
      component: 'elSelect',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.material.materialCode'),
      data: that.materialCodeList
    },
    // 物料名称
    {
      name: 'materialName',
      value: row.materialName || '',
      span: 12,
      component: 'elInput',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.material.materialName')
    }
  ]
  moreQueryData.forEach((item) => {
    if (!item.mchange) {
      item.mchange = that.onFormChange
    }
  })
  return moreQueryData
}
// 查询列表列表
export const getSearchTableItem = (that) => {
  const searchTableItem = [
    // 工位位置
    {
      prop: 'workPositionCode',
      label: that.$t('lang.gles.workflow.workPosition'),
      isShow: true,
      showOverflowTooltip: true,
      sortable: true
    },
    // 工位类型
    {
      prop: 'workPositionTypeName',
      label: that.$t('lang.gles.workflow.workPositionType'),
      isShow: true,
      showOverflowTooltip: true
    },
    // 工位名称
    {
      prop: 'workPositionName',
      label: `${that.$t('lang.gles.workflow.workPositionName')}`,
      isShow: true,
      showOverflowTooltip: true
    },
    // 物料编码
    {
      prop: 'materialCode',
      label: that.$t('lang.gles.material.materialCode'),
      isShow: true,
      showOverflowTooltip: true
    },
    // 物料名称
    {
      prop: 'materialName',
      label: that.$t('lang.gles.material.materialName'),
      isShow: true,
      showOverflowTooltip: true
    },
    // 备注
    {
      prop: 'remark',
      label: `${that.$t('lang.gles.base.materialConfig.workPositionRemarks')}`,
      isShow: true,
      showOverflowTooltip: true
    }
  ]

  searchTableItem.forEach((item) => (item.showOverflowTooltip = true))
  return searchTableItem
}

// 编辑弹框 基础信息
export const getEditBaseFormData = (that, options, row, mode) => {
  console.log(options.materialCodeList, '------物料编码')
  const formData = [
    // 工位类型
    {
      name: 'workPositionType',
      value: row.workPositionType || '',
      span: 24,
      component: 'elSelect',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.workflow.workPositionType'),
      data: that.materialTypsList,
      clearable: true,
      rules: mapVerify(['required', 'inputCode']),
      class: 'tip-icon',
      mchange: that.handleWorkPositionType
    },
    // 工位位置
    {
      name: 'workPositionId',
      value: row.workPositionId || '',
      value1: row.workPositionNextlabel || '',
      span: 24,
      component: 'elCascader',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.workflow.workPosition'),
      options: that.workPositionList,
      mchange: that.handleWorkPosition,
      clearable: true,
      placeholder: '200个字符以内，支持任意字符',
      rules: mapVerify(['required']),
      key: row.workPositionKey || 0
    },
    // 工位名称
    {
      name: 'workPositionName',
      value: row.workPositionName || '',
      span: 24,
      component: 'elInput',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.workflow.workPositionName'),
      clearable: true,
      filterable: true,
      rules: mapVerify(['required', 'inputLen50']),
      mchange: that.handleFactoryChange
    },
    // 物料编码
    {
      name: 'materialId',
      value: row.materialId || '',
      span: 24,
      component: 'elSelect',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.material.materialCode'),
      data: options.materialCodeList,
      clearable: true,
      filterable: true,
      disabled: !!that?.formModel?.warehouseId,
      popperAppendToBody: false,
      popperClass: 'materCode',
      rules: mapVerify(['required']),
      class: 'tip-icon'
    },
    // 备注
    {
      name: 'remark',
      value: row.remark || '',
      span: 24,
      component: 'elInput',
      type: 'textarea',
      rows: 6,
      adaptionw: true,
      showWordLimit: false,
      clearable: true,
      label: `${that.$t('lang.gles.base.materialConfig.workPositionRemarks')}`,
      rules: mapVerify(['inputLen200'])
    }
  ]
  formData.forEach((item) => {
    if (!item.mchange) {
      item.mchange = that.onFormChange
    }
  })
  return formData
}
